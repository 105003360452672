export enum ChargeableType {
  Booking = 'Booking',
  PurchasedStoreItem = 'PurchasedStoreItem'
}

export const VouchersTypeEnum = {
  ActivityVoucher: 'ActivityVoucher',
  CreditVoucher: 'CreditVoucher',
  ClassPasses: 'ClassPasses',
  PurchasedClassPass: 'PurchasedClassPass'
}
