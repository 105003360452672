import { present, defined, definedNotNull } from 'shared/presenters/presenter'
import { translatablePresenter } from 'shared/presenters/translatablePresenter'
import { Schedulables } from 'shared/presenters/graphqlTypes'
import determineImageFromSchedulableImages, {
  DetermineImageFromSchedulableProps
} from 'shared/helpers/determineImageFromSchedulable'
import { DEFAULT_FALLBACK_IMAGE } from 'enums/image'
import formatDurationText from 'shared/helpers/formatDurationText'
import { Translate } from 'next-translate'
import dayjs from 'dayjs'

export class SchedulablePresenter extends translatablePresenter(
  present<Schedulables>()
) {
  name = () => this.translateField('name')
  description = () => this.translateField('description')
  whatToBring = () => this.translateField('what_to_bring')
  itinerary = () => this.translateField('itinerary')
  requirements = () => this.translateField('requirements')
  tagline = () => this.translateField('tagline')

  /**
   * Does the schedulable allow children?
   */
  childrenAllowed = () => [0, 1, 3].includes(definedNotNull(this.pricing_model))

  /**
   * Does the schedulable allow adults?
   */
  adultsAllowed = () => [0, 1, 2].includes(definedNotNull(this.pricing_model))

  /**
   * Does the schedulable only allow children?
   */
  childrenOnlyAllowed = () => !this.adultsAllowed() && this.childrenAllowed()

  /**
   * Starting price of the activity which is the
   * min of adult/child price or whichever is defined.
   * Allows for £0 say if the activity is free for children when accompanied.
   */
  priceStartingFrom = () => {
    const pricePence = defined(this.price_pence)
    const childPricePence = defined(this.child_price_pence)
    const hasAdults = this.adultsAllowed() && pricePence !== null
    const hasChildren = this.childrenAllowed() && childPricePence !== null

    if (hasAdults && hasChildren) {
      return Math.min(pricePence, childPricePence) // allows for £0
    } else if (hasAdults) {
      return pricePence
    } else if (hasChildren) {
      return childPricePence
    } else {
      throw 'All fetched prices are null'
    }
  }

  /**
   * Calculates total price for attendees.
   * An example of where this is used is in the Summary component for the BookingConfig
   * view.
   */
  totalPriceForAttendees = ({
    adults = 0,
    children = 0
  }: {
    adults?: number
    children?: number
  }) => {
    let totalPrice = 0

    const pricePence = defined(this.price_pence)
    const childPricePence = defined(this.child_price_pence)

    if (pricePence == null && childPricePence == null) {
      throw 'All fetched prices are null.'
    }

    if (children) {
      if (childPricePence === null) {
        throw 'Children specified but child_price_pence is null'
      }
      totalPrice += children * childPricePence
    }

    if (adults) {
      if (pricePence === null) {
        throw 'Adults specified but price_pence is null'
      }
      totalPrice += adults * pricePence
    }

    return totalPrice
  }

  /**
   * Return the most specific image possible for this schedulable, resized.
   */
  imageURL = (
    transform: DetermineImageFromSchedulableProps['transform'] = 'chargeable'
  ) => {
    const imageUrl = determineImageFromSchedulableImages({
      image: this.image,
      defaultImage: this.default_image,
      activityType: this.activity_type,
      transform
    })
    if (imageUrl) {
      return imageUrl
    }

    return DEFAULT_FALLBACK_IMAGE
  }

  getDurationInHours = () => Math.floor(this.duration_mins ?? 0 / 60)

  /**
   * In the business app, duration is selected from a number of hours up to 12, and then multiples of days.
   * If durationMins is less than 720, then durationMins represents the length of activity.
   * If durationMins is greater than 720, then it represents the number of days the activity will run on.
   * For example, a duration of 4320 means the activity runs on 3 consecutive days.
   * The actual duration in minutes in this case is defined by the start and finish time chosen by the outlet.
   */
  getDurationInDays = () => {
    const durationMins = this.duration_mins ?? 0
    return durationMins > 720 ? Math.ceil(durationMins / 1440) : 0
  }

  getDurationInNights = () => this.getDurationInDays() - 1

  isMultiDay = () => this.getDurationInDays() > 0

  formatDurationText = (t: Translate, variant?: string) =>
    formatDurationText({
      durationInMins: this.duration_mins ? this.duration_mins : 0,
      continuous: this.continuous,
      variant,
      t
    })

  /**
   * Is the location determined on the day (no address given)?
   */
  locationOnTheDay = () => defined(this.address) == null

  /**
   * Are any group discounts available?
   */
  groupDiscountsAvailable = () => defined(this.price_points).length > 0

  addressSummaryLine = (): string | undefined => {
    const city = this.address?.city
    const country = this.address?.country

    if (city && country) {
      return `${city}, ${country}`
    } else if (city || country) {
      return (city as string) ?? (country as string)
    } else {
      return undefined
    }
  }

  getCoordinates = () => {
    const latitude = this.address?.latitude
    const longitude = this.address?.longitude

    if (!latitude || !longitude) return

    return {
      latitude,
      longitude
    }
  }

  googleMapsImageURL = () => {
    const latitude = this.address?.latitude
    const longitude = this.address?.longitude

    if (!latitude || !longitude) return

    return [
      // GOOGLE_MAPS_IMAGE_PREFIX,
      'https://maps.googleapis.com/maps/api/staticmap?',
      'key=',
      process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      '&visual_refresh=true',
      '&markers=icon:https://res.cloudinary.com/eola/image/upload/h_30/v1511334399/eola/public_images/gmaps-markerv1.png%7C',
      [latitude, longitude].join(','),
      '&size=',
      320,
      'x',
      240,
      '&scale=',
      2, // retina,
      '&zoom=',
      14
    ].join('')
  }

  googleMapsURL = () => {
    const latitude = this.address?.latitude
    const longitude = this.address?.longitude

    if (!latitude || !longitude) return

    return `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
  }

  hasDetails = () => {
    return (
      this?.description() ||
      this?.whatToBring() ||
      this?.itinerary() ||
      this?.requirements()
    )
  }

  lowestResourceReqPrice = (
    resourceReqs = defined(this.resource_requirements)
  ): number => {
    const resourceReqPrices = resourceReqs?.map(
      resourceReq => resourceReq?.price_pence
    )
    return resourceReqPrices && resourceReqPrices?.length > 0
      ? Math.min(...(resourceReqPrices as number[]))
      : 0
  }

  hasEnoughPeopleGoingForExclusive = (peopleGoing: number): boolean => {
    if (this.allow_exclusive_bookings) {
      return (
        peopleGoing >= definedNotNull(this.minimum_party_size_for_exclusive)
      )
    } else {
      return true
    }
  }

  /**
   * This method is used to determine whether the schedulable is bookable this weekend.
   * WARN: This method requires specific query data to be returned to be valid.
   * WARN: time_slot_bookings_aggregate must be filtered to weekend time slots.
   */
  availableThisWeekend = () => {
    const saturday = dayjs().day(6).format('YYYY-MM-DD')
    const sunday = dayjs().day(7).format('YYYY-MM-DD')
    return (
      definedNotNull(this.materialized_bookability_by_days).filter(
        bookability => {
          return (
            bookability?.on_date == saturday || bookability?.on_date == sunday
          )
        }
      ).length > 0
    )
  }

  /**
   * Counts total availability remaining, if less than 10 spots left, return true.
   */
  fewSpotsLeft = () => {
    return (
      definedNotNull(this.materialized_bookability_by_days).reduce(
        (partialSum, bookability) =>
          partialSum + (bookability?.max_availability || 0),
        0
      ) <= 10
    )
  }
}
